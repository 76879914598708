import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import useSearchParams from '../../hooks/useSearchParams'
import styles from './BasicInventoryFilter.module.css'
require('./BasicInventoryFilter.less')
import { Locations } from '../../services/api/locations'
import { LocationTypes } from '../location-modal'
import { capitalizeWord, convertEnumToString } from '../../utils'
import DropdownOverlay from '../dropdown-overlay'
import CustomSearchableSelectSecondary from '../custom-searchable-select-secondary'
import { GlobalFilterContext } from '../../contexts/GlobalFilter'
import { useRouter } from 'next/router'
import Input from '../input'
import moment from 'moment'
import { Users } from '../../services/api/users'
import { Button, Checkbox, Select } from 'antd'
import { quantityChangeTypeOptions } from '../../utils/constants'
import CustomCascader from '../custom-cascader'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProductCategories } from '../../store/products/actions'
import { Products } from '../../services/api/products'
import isEqual from 'lodash.isequal'

const BasicInventoryFilter = ({
	isFilterDropdownOpen,
	setIsFilterDropdownOpen
}) => {
	const router = useRouter()
	const dispatch = useDispatch()
	const shouldResetFilter = useContext(GlobalFilterContext)
	const { tab } = router.query
	const { searchParams, applyFilter, clearFilter } = useSearchParams()
	const [startDate, setStartDate] = useState(searchParams.startDate || null)
	const [endDate, setEndDate] = useState(searchParams.endDate || null)
	const [users, setUsers] = useState([])
	const [updatedBy, setUpdatedBy] = useState()
	const [locations, setLocations] = useState()
	const [location, setLocation] = useState()
	const [searchTerm, setSearchTerm] = useState('')
	const [isLoadingLocations, setIsLoadingLocations] = useState(false)
	const [isLoadingUsers, setIsLoadingUsers] = useState(false)
	const [quantityChangeType, setQuantityChangeType] = useState([])
	const { categories } = useSelector(state => state.productsReducer)
	const [categoryOptions, setCategoryOptions] = useState()
	const [selectedCategories, setSelectedCategories] = useState(
		[
			searchParams.category,
			searchParams.subCategory1,
			searchParams.subCategory2,
			searchParams.subCategory3,
			searchParams.subCategory4,
			searchParams.subCategory5
		].filter((category) => category)
	)
	const [specifications, setSpecifications] = useState([])
	const [selectedAttributes, setSelectedAttributes] = useState([])
	const [selectedAttributeValues, setSelectedAttributeValues] = useState({})
	const prevSelectedAttributeValues = useRef(selectedAttributeValues)

	const selectedSpecifications = useMemo(() => {
		return specifications.filter(spec => selectedAttributes.includes(spec.key))
	}, [selectedAttributes])

	useEffect(() => {
		prevSelectedAttributeValues.current = selectedAttributeValues
	}, [selectedAttributeValues])

	useEffect(() => {
		dispatch(fetchProductCategories())
	}, [])

	useEffect(() => {
		createCategoryOptions()
	}, [categories])

	useEffect(() => {
		if (isFilterDropdownOpen) {
			fetchWarehouses()
			fetchUsers()
		}
	}, [isFilterDropdownOpen])

	useEffect(() => {
		if (!shouldResetFilter) {
			return
		}
		resetFilter()
	}, [shouldResetFilter])

	useEffect(() => {
		if (!locations?.length || !searchParams.locationId) {
			return
		}
		const location = locations.find(({ id }) => id === searchParams.locationId)

		setLocation({
			id: location.id,
			key: location.id,
			label: location.label,
			data: location
		})
	}, [locations])

	useEffect(() => {
		if (!specifications?.length || !searchParams.attributes || !searchParams.attributes.length) {
			return
		}

		// Formatting: ['key:value1,value2', 'key2:value3', ...]
		const attributes = searchParams.attributes
		const newSelectedAttributeValues = {}
		const values = attributes.map(attribute => {
			const [key, values] = attribute.split(':')
			newSelectedAttributeValues[key] = values?.split(',') || []
			return key
		})

		setSelectedAttributes(values)
		setSelectedAttributeValues(newSelectedAttributeValues)

	}, [specifications])

	useEffect(() => {
		if (!users.length || !searchParams.updatedBy) {
			return
		}
		const user = users.find(({ id }) => id === searchParams.updatedBy)

		setUpdatedBy({
			id: user.id,
			key: user.id,
			label: user.name,
			data: user
		})
	}, [users])

	useEffect(() => {
		getDefaultSpecifications()
	}, [])

	const getDefaultSpecifications = async () => {
		const { data } = await Products.fetchSpecifications({ types: ['STRING', 'NUMBER'] })
		setSpecifications(data.filter(spec => spec.options && spec.options.length > 0))
		return data
	}

	const createCategoryOptions = () => {
		const options = []
		const createOptions = (categories, options) => {
			Object.keys(categories).forEach(categoryKey => {
				const option = {
					value: categoryKey,
					label: categories[categoryKey].label,
					children: []
				}
				options.push(option)
				if (categories[categoryKey].subCategories) {
					createOptions(categories[categoryKey].subCategories, option.children)
				}
			})
		}
		createOptions(categories, options)
		setCategoryOptions(options)
	}

	const fetchWarehouses = async () => {
		setIsLoadingLocations(true)
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
		if (response.data) {
			setLocations(response.data.results)
		}
		setIsLoadingLocations(false)
	}

	const fetchUsers = async () => {
		setIsLoadingUsers(true)
		const response = await Users.index()
		if (response.data) {
			setUsers(response.data.results)
		}
		setIsLoadingUsers(false)
	}

	const searchLocations = async value => {
		return Locations.index({ searchTerm: value, types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
	}

	const searchUsers = value => {
		return Users.index({
			searchTerm: value
		})
	}

	const handleApply = async () => {
		const [category, subCategory1, subCategory2, subCategory3, subCategory4, subCategory5] = selectedCategories
		const filters = {
			searchTerm,
			category,
			subCategory1,
			subCategory2,
			subCategory3,
			subCategory4,
			subCategory5
		}
		if (location) {
			filters.locationId = location.key
		}
		if (startDate) {
			filters.startDate = moment(startDate).startOf('day').toDate()
		}
		if (endDate) {
			filters.endDate = moment(endDate).endOf('day').toDate()
		}
		if (updatedBy) {
			filters.updatedBy = updatedBy.key
		}
		if (quantityChangeType.length === 1) {
			filters.quantityChangeType = quantityChangeType[0]
		}
		if (Object.keys(selectedAttributeValues).length > 0) {
			const attributeFilters = []
			Object.keys(selectedAttributeValues).forEach(key => {
				const values = selectedAttributeValues[key]
				if (values.length > 0) {
					attributeFilters.push(`${key}:${values.join(',')}`)
				} else {
					attributeFilters.push(`${key}:${undefined}`)
				}
			})
			filters.attributes = attributeFilters
		}

		applyFilter(filters)
		setIsFilterDropdownOpen(false)
	}

	const resetFilter = () => {
		setSearchTerm('')
		setLocation(null)
		setStartDate(null)
		setEndDate(null)
		setUpdatedBy(null)
		setQuantityChangeType([])
		setSelectedCategories([])
		setSelectedAttributes([])
		setSelectedAttributeValues({})
		clearFilter()
	}

	const isFilterEnabled = () => {
		const attributesModified = !isEqual(selectedAttributeValues, prevSelectedAttributeValues.current)
		return searchTerm.trim().length > 0 ||
            location ||
            startDate ||
            endDate ||
            updatedBy ||
            quantityChangeType.length > 0 ||
            selectedCategories.length > 0 ||
            selectedAttributes.length > 0 ||
            attributesModified
	}

	return (
		<DropdownOverlay className='dropdown-overlay'>
			{
				tab === 'logs' &&
				<>
					<div>
						<p className={styles.title}>Date Range</p>
						<div className={styles.inputFlex}>
							<Input
								style={{ background: '#F7F7F7', borderRadius: 0 }}
								type='date'
								title='Start Date'
								value={startDate ? moment(startDate) : null}
								onChange={(date) => setStartDate(date?.toDate())}
								popupContainer={() => document.querySelector('.dropdown-overlay')}
							/>
							<Input
								style={{ background: '#F7F7F7', borderRadius: 0 }}
								type='date'
								title='End Date'
								value={endDate ? moment(endDate) : null}
								onChange={(date) => setEndDate(date?.toDate())}
								disabledDate={current => current && current.valueOf() < moment(startDate)}
								popupContainer={() => document.querySelector('.dropdown-overlay')}
							/>
						</div>
					</div>
					<div>
						<p className={styles.title}>Updated By</p>
						<CustomSearchableSelectSecondary
							isLoading={isLoadingUsers}
							defaultOptions={users}
							searchTask={searchUsers}
							customLabel={(data) => convertEnumToString(data?.name)}
							customDescription={(data) => convertEnumToString(capitalizeWord(data?.role))}
							valueIndex='id'
							labelIndex='name'
							descriptionIndex='role'
							placeholder='Select User'
							onChange={setUpdatedBy}
							onClear={() => setUpdatedBy(null)}
							value={updatedBy}
							allowClear={true}
						/>
					</div>
				</>
			}
			<div>
				<p className={styles.title}>Categories</p>
				<CustomCascader
					className='product-category-cascader'
					allowClear={false}
					placeholder='Select a category and/or sub-categories...'
					changeOnSelect={true}
					options={categoryOptions}
					value={selectedCategories}
					onChange={setSelectedCategories}
				/>
			</div>
			<div>
				<p className={styles.title}>Warehouse</p>
				<CustomSearchableSelectSecondary
					containerStyle={{ width: 320 }}
					searchTask={searchLocations}
					defaultOptions={locations}
					isLoading={isLoadingLocations}
					customLabel={(data) => data.label ? data.label : convertEnumToString(data.type)}
					valueIndex='id'
					descriptionIndex='address'
					placeholder='Select Warehouse'
					onChange={location => setLocation(location)}
					onClear={() => setLocation(null)}
					value={location}
				/>
			</div>
			{
				tab !== 'rawMaterial' &&
				<div>
					<p className={styles.title}>Attributes</p>
					<Select
						mode='multiple'
						allowClear
						style={{ width: '100%' }}
						placeholder='Select Attributes'
						className='basic-inventory-attribute-select'
						value={selectedAttributes}
						onChange={(values) => {
							setSelectedAttributes(values)
							const newSelectedAttributeValues = {}
							values.forEach(value => {
								newSelectedAttributeValues[value] = selectedAttributeValues[value] || []
							})
							setSelectedAttributeValues(newSelectedAttributeValues)
						}}
					>
						{
							specifications.map(spec => {
								return (
									<Select.Option key={spec.key} value={spec.key}>
										{spec.label}
									</Select.Option>
								)
							})
						}
					</Select>
					{
						selectedSpecifications.length > 0 &&
							<div style={{ display: 'flex', flexDirection: 'column', gap: 8, marginTop: 12 }}>
								{
									selectedSpecifications.map(spec => {
										return (
											<div key={spec.key} className={styles.specification}>
												<p className={styles.title} style={{ fontSize: 14 }}>{spec.label}</p>
												<Select
													mode='multiple'
													allowClear
													style={{ width: '100%' }}
													placeholder='Select Options'
													className='basic-inventory-attribute-option-select'
													value={selectedAttributeValues[spec.key]}
													onChange={values => setSelectedAttributeValues({ ...selectedAttributeValues, [spec.key]: values })}
												>
													{
														spec.options.map(option => {
															return (
																<Select.Option key={option.value} value={option.value}>
																	{option.value}
																</Select.Option>
															)
														})
													}
												</Select>
											</div>
										)
									})
								}
							</div>
					}
				</div>
			}
			{
				tab === 'logs' &&
				<div>
					<p className={styles.title}>Products</p>
					<Checkbox.Group
						className={styles.boundContainer}
						value={quantityChangeType}
						onChange={e => setQuantityChangeType(e)}
					>
						{
							quantityChangeTypeOptions.map((option) => {
								return (
									<Checkbox
										key={option.value}
										className={styles.inputTitle}
										value={option.value}
									>
										{option.label}
									</Checkbox>
								)
							})
						}
					</Checkbox.Group>
				</div>
			}
			<div className={styles.buttons}>
				{
					isFilterEnabled() &&
					<Button
						className={`${styles.button} ${styles.resetButton}`}
						onClick={resetFilter}
					>
						<span className={styles.buttonText}>
							Reset All
						</span>
					</Button>
				}
				<Button
					type='primary'
					className={styles.button}
					disabled={!isFilterEnabled()}
					onClick={handleApply}
				>
					<span className={styles.buttonText}>
						Apply Filter
					</span>
				</Button>
			</div>
		</DropdownOverlay>
	)
}

BasicInventoryFilter.propTypes = {
	isFilterDropdownOpen: PropTypes.bool,
	setIsFilterDropdownOpen: PropTypes.func
}

export default BasicInventoryFilter
