import { useSelector } from 'react-redux'
import styles from './CclDetails.module.css'
require('./CclDetails.less')
import { useEffect, useMemo, useState } from 'react'
import { Button, notification, Progress, Table, Tooltip } from 'antd'
import { Ccl } from '../../services/api/ccl'
import { isValidInternationalPhoneNumber } from '../../utils'
import { CaretUpOutlined, CaretDownOutlined, ReloadOutlined } from '@ant-design/icons'
import moment from 'moment'

const cclColumns = [
	{
		title: <div style={{ fontSize: 10 }}>Courier</div>,
		key: 'courier',
		render: (cclResult) => {
			return (
				<div style={{ fontSize: 10 }}>
					{cclResult.type}
				</div>
			)
		}
	},
	{
		title: <div style={{ fontSize: 10 }}>Total</div>,
		key: 'total',
		render: (cclResult) => {
			const result = cclResult.result
			return (
				<div style={{ fontSize: 10 }}>
					{result.total}
				</div>
			)
		}
	},
	{
		title: <div style={{ color: '#288ea5', fontSize: 10 }}>Delivered</div>,
		key: 'delivered',
		render: (cclResult) => {
			const result = cclResult.result
			return (
				<div style={{ fontSize: 10 }}>
					{result.delivered}
				</div>
			)
		}
	},
	{
		title: <div style={{ color: '#ff5959', fontSize: 10 }}>Undelivered</div>,
		key: 'returned-cancelled',
		render: (cclResult) => {
			const result = cclResult.result
			return (
				<div style={{ fontSize: 10 }}>
					{result.flagged + result.cancelled}
				</div>
			)
		}
	},
	{
		title: <div style={{ fontSize: 10 }}>Confidence</div>,
		key: 'confidence-level',
		render: (cclResult) => {
			const result = cclResult.result
			return (
				<div style={{ fontSize: 10 }}>
					{`${result.confidence.toFixed(2)} %`}
				</div>
			)
		}
	}
]

const CclDetails = ({
	phone,
	autoCheck = false
}) => {
	const { companyDetails, userProfile } = useSelector(state => state.authReducer)
	const [isFetchingCcl, setIsFetchingCcl] = useState(false)
	const [cclDetails, setCclDetails] = useState(null)
	const [isCheckingCcl, setIsCheckingCcl] = useState(false)
	const [isCclTableVisible, setIsCclTableVisible] = useState(true)

	const confidenceLevel = useMemo(() => {
		if (cclDetails) {
			let total = 0
			let delivered = 0
			for (const cclResult of cclDetails.record.cclResults) {
				total += cclResult.result.total
				delivered += cclResult.result.delivered
			}
			const confidence = total > 0 ? delivered / total * 100 : 0
			return confidence
		} else {
			return 0
		}
	}, [cclDetails])

	const summaryRow = useMemo(() => {
		const summaryRow = {
			total: 0,
			delivered: 0,
			flagged: 0,
			cancelled: 0,
			confidence: 0
		}
		if (cclDetails) {
			for (const cclResult of cclDetails.record.cclResults) {
				summaryRow.total += cclResult.result.total
				summaryRow.delivered += cclResult.result.delivered
				summaryRow.flagged += cclResult.result.flagged
				summaryRow.cancelled += cclResult.result.cancelled
			}
			summaryRow.confidence = summaryRow.total > 0 ? summaryRow.delivered / summaryRow.total * 100 : 0
		}
		return summaryRow
	}, [cclDetails])

	useEffect(() => {
		if (companyDetails?.isCclCheckEnabled) {
			if (companyDetails?.isCclCheckAutomatic && autoCheck) {
				checkCustomerConfidenceLevel()
			}
			getCustomerConfidenceLevel()
		}
	}, [companyDetails, phone, autoCheck])

	const getCustomerConfidenceLevel = async () => {
		try {
			setIsFetchingCcl(true)
			const { data } = await Ccl.fetchCcl(phone)
			setCclDetails(data)
		} catch (e) {
			console.error(e)
		} finally {
			setIsFetchingCcl(false)
		}
	}

	const checkCustomerConfidenceLevel = async () => {
		try {
			if (!phone) {
				return
			}
			if (!isValidInternationalPhoneNumber(phone)) {
				return
			}
			setIsCheckingCcl(true)
			const { data } = await Ccl.checkCcl(phone)
			setCclDetails(data)
		} catch (e) {
			notification.error({
				message: 'Unable to Check Delivery Success Rate',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsCheckingCcl(false)
		}
	}

	if (userProfile?.company?.country !== 'BD' || !companyDetails?.isCclCheckEnabled) {
		return null
	}

	return (
		<div className={`${styles.container} ccl-details`}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div className={styles.title}>Delivery Success Rate</div>
				<Tooltip
					title='Refresh the Delivery Success Rate to get the updated analytics'
				>
					<Button
						style={{ marginLeft: 'auto' }}
						onClick={checkCustomerConfidenceLevel}
						loading={isCheckingCcl || isFetchingCcl}
						type='primary'
					>
						{
							!(isCheckingCcl || isFetchingCcl) &&
                        <ReloadOutlined />
						}
					</Button>
				</Tooltip>
			</div>
			{
				!cclDetails && !isFetchingCcl && !isCheckingCcl &&
				<div className={styles.noDataContainer}>
					<div className={styles.noFileIconContainer}>
						<img src='/img/file-error.svg' alt='File error icon' />
					</div>
					<p className={styles.noDataText}>
						No Data
					</p>
				</div>
			}
			{
				cclDetails &&
				<div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
					<div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
						<Progress
							percent={confidenceLevel}
							format={() => `${confidenceLevel.toFixed(2)} %`}
							className={confidenceLevel === 0 && summaryRow.total === 0 ? 'neutral' : 'non-neutral'}
						/>
						<div style={{ width: 24 }} />
						<Button
							style={{ marginLeft: 'auto' }}
							loading={isCheckingCcl || isFetchingCcl}
							size='small'
							onClick={() => setIsCclTableVisible(!isCclTableVisible)}
							icon={isCclTableVisible ? <CaretUpOutlined /> : <CaretDownOutlined />}
						>
						Details
						</Button>
					</div>
					<div style={{ fontSize: 10 }}>
						{`Updated at ${moment(cclDetails.updatedAt).format('MMM D, YYYY h:mm A')} by ${cclDetails.user.name}`}
					</div>
				</div>
			}
			{
				isCclTableVisible && cclDetails &&
				<div>
					<Table
						size='small'
						columns={cclColumns}
						dataSource={cclDetails.record.cclResults}
						pagination={false}
						bordered={true}
						summary={() => {
							return (
								<Table.Summary.Row>
									<Table.Summary.Cell>
										<div style={{ fontSize: 10 }}>
									Total
										</div>
									</Table.Summary.Cell>
									<Table.Summary.Cell>
										<div style={{ fontSize: 10 }}>
											{summaryRow.total}
										</div>
									</Table.Summary.Cell>
									<Table.Summary.Cell>
										<div style={{ fontSize: 10 }}>
											{summaryRow.delivered}
										</div>
									</Table.Summary.Cell>
									<Table.Summary.Cell>
										<div style={{ fontSize: 10 }}>
											{summaryRow.cancelled + summaryRow.flagged}
										</div>
									</Table.Summary.Cell>
									<Table.Summary.Cell>
										<div style={{ fontSize: 10 }}>
											{`${summaryRow.confidence.toFixed(2)} %`}
										</div>
									</Table.Summary.Cell>
								</Table.Summary.Row>
							)
						}}
					/>
				</div>
			}
		</div>
	)
}

export default CclDetails
