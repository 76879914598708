import PropTypes from 'prop-types'
import CustomDeliveryAreaSelect from '../custom-delivery-area-select'
import { useEffect, useState } from 'react'
import { DeliveryPartners } from '../../services/api/delivery-partner'

const PickUpOutletSelectPandago = ({ title, error, value, defaultValue, onChange, deliveryPartnerId }) => {
	const [isLoading, setIsLoading] = useState(false)
	const [outlets, setOutlets] = useState([])

	useEffect(() => {
		getPickUpOutlets()
	}, [])

	useEffect(() => {
		if (outlets.length > 0 && defaultValue?.value) {
			onChange(outlets.find(item => item.value === defaultValue.value))
		}
	}, [outlets, defaultValue?.value])

	const getPickUpOutlets = async () => {
		try {
			setIsLoading(true)
			const { data } = await DeliveryPartners.getStores(deliveryPartnerId)
			const options = data?.map(item => {
				const option = {
					...item,
					value: item.client_vendor_id,
					label: item.name,
					description: item.address
				}
				return option
			}) || []
			setOutlets(options)
		} catch (e) {
			console.error(e)
		} finally {
			setIsLoading(false)
		}
	}

	const onOutletChange = (_, option) => {
		onChange(option ? { ...option } : null)
	}

	return (
		<CustomDeliveryAreaSelect
			title={title}
			error={error}
			levels={[
				{
					id: 'outlet',
					placeholder: 'Select Outlet',
					loading: isLoading,
					options: outlets,
					value: outlets.length > 0 ? value : null,
					onChange: onOutletChange,
					onClear: () => onOutletChange(),
					style: { width: '100%', maxWidth: '100%' }
				}
			]}
		/>
	)
}

PickUpOutletSelectPandago.propTypes = {
	deliveryPartnerId: PropTypes.string,
	title: PropTypes.string,
	error: PropTypes.bool,
	value: PropTypes.object,
	onChange: PropTypes.func
}

PickUpOutletSelectPandago.defaultProps = {
	deliveryPartnerId: '',
	title: 'Pick Up Outlet',
	error: false,
	value: null,
	onChange: () => {}
}

export default PickUpOutletSelectPandago
